import {Link} from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {getAndSetLang, isLangEng, isLangIt} from "../utils/language"


const IndexPage = () => {
  let lang = getAndSetLang("it")

  let content
  let title

  if( isLangIt() ){
      title="About"
      content =
      <>
        <p>Contest! Clicca su questo <Link to="https://forms.gle/9jfcnkjxUMh5AyGF7">link (https://forms.gle/9jfcnkjxUMh5AyGF7)</Link> e scrivi tu di me!</p>
        <p>Il migliore lo pubblico 😈</p>
      </>
  }
  if( isLangEng() ){
    title="About"
    content = 
      <>
        <p>Contest! Click on this <Link to="https://forms.gle/9jfcnkjxUMh5AyGF7">link (https://forms.gle/9jfcnkjxUMh5AyGF7)</Link> and write about me!</p>
        <p>The best will be published 😈</p>
      </>
  }

  return (
    <Layout pageTitle={title} lang={lang}>
      {content}
    </Layout>
  )
}

export default IndexPage

export const Head = () => <Seo title={"About"} />
